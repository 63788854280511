import React from 'react'
import { getAssetUrl } from '../services/cdn'
import { Link } from 'gatsby'
import { Header, Section } from './Blocks'
import { BranchesMap, Container as BlockContainer } from './Blocks'
import { Hero } from './Sections'
import Contact from './Forms/Contact'
import Promos from './Sections/Promos'
//import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => {
    return (
        <footer>
            <Hero src="app/SIDC-Membersip.jpg" size={12} height={650} x={30} y={10} dim={0.5} autoAlign={false}>
                <div className="column end">
                    <h1 className="text-white text-center"><b>BE AN ACTIVE MEMBER NOW</b></h1>
                    <a className="btn btn-primary" href={process.env.GATSBY_SITE_MAIN + '/membership'}>Learn More</a>
                </div>
            </Hero>
            <BlockContainer padding={5} dark id="features">
                <Container>
                    <Row>
                    <Col md="12">
                        <div className="text-center">
                        <h2 className="pb-4 text-dark text-left text-md-center">Featured benefits with SIDC membership</h2>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg="4" md="6" className="align-self-start text-justify">
                        <div className="mb-5">
                            <h4 className="text-primary text-left">PATRONAGE REFUND</h4>
                            <p>The way of Cooperative of sharing its profits among member-owners based on their purchases, which circulates money back into our community, helping to build a stronger local economy.</p>
                        </div>
                        <div className="mb-5">
                            <h4 className="text-primary text-left">SCHOLARSHIP GRANT</h4>
                            <p>Given to a child of a regular member with a good record or active member who has purchased at least 20,000.00 last year and has a good community record. The course need is related to the business of the cooperative</p>
                        </div>
                        <div className="mb-5">
                            <h4 className="text-primary text-left">FREE MEDICAL CHECK-UP</h4>
                            <p>Benefits given to the legitimate member of SIDC at given specific schedule.</p>
                        </div>
                        <div className="mb-5">
                            <h4 className="text-primary text-left">TECHNICAL & MARKETING ASSISTANCE</h4>
                            <p>This is part of Cooperative’s support to its member as added value to their purchased and availed SIDC products & services.</p>
                        </div>
                        <div className="mb-5">
                            <h4 className="text-primary text-left">MEMBER YEARLY INCENTIVE</h4>
                            <p>Given to a member every end of the year. The amount of incentive is based on their  purchases and availed services.</p>
                        </div>
                    </Col>
                    <Col md="4" className="my-3 d-none d-lg-block"> 
                        <img className="img-fluid d-block pt-5" alt="SIDC BENEFITS" src={getAssetUrl('app/SIDC-Benefits.jpg')} />
                    </Col>
                    <Col lg="4" md="6" className="align-self-start text-justify">
                        <div className="mb-5">
                            <h4 className="text-primary text-left">SIDC CARE</h4>
                            <p>SIDC Care is a medical benefit for qualified SIDC members. The amount of benefit available depends on the amount of purchase products and availed services.</p>
                        </div>
                        <div className="mb-5">
                            <h4 className="text-primary text-left">STUDY NOW, PAY LATER</h4>
                            <p>An educational financial assistance program of SIDC designed for the members with good scholastic and community record, to have an access on tertiary level.</p>
                            <p>Loan recipient shall begin paying their loans after completion of the course for which the loan expended.</p>
                        </div>
                        <div className="mb-5">
                            <h4 className="text-primary text-left">MORTUARY BENEFITS</h4>
                            <p>Provided to legal beneficiaries of late members. Must be active and have a good record membership.</p>
                        </div>
                        <div className="mb-5">
                            <h4 className="text-primary text-left">SEMINARS & TRAININGS</h4>
                            <p>SIDC provides Seminars & Training to different community. Modules are based according to their needs. This will help them have new ideas and skills for own productivity.</p>
                        </div>
                    </Col>
                    <Col lg="6" md="6" className="mx-auto text-justify">
                        <h4 className="text-primary text-left">COMMUNITY DEVELOPMENT FUND</h4>
                        <p className="mb-5">As part of the Cooperative Principle to help the community for its sustainability. SIDC allocate Barangay Development Fund for the project or activities</p>
                    </Col>
                    </Row>
                </Container>
            </BlockContainer>
            <Promos />
            <BlockContainer 
                padding={5} 
                className="text-white"
                style={{
                    backgroundPosition: '100% 10%',
                    backgroundSize: 'cover, cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${getAssetUrl('consumer/Testimonial-Consumer-Retailing-1.jpg')}')`,
                }}
            >
                <Container>
                    <Row>
                        <Col md="8" lg="6" className="mr-auto text-left text-white">
                            <p className="lead text-white">"Sumapi ako sa SIDC dahil nakita ko na matatag ang  pundasyon, maganda ang mga programa at napakalawak ng sakop. Maganda at maayos ang mga training sa  pagnenegosyo na ipinagkakaloob nila sa mga kasapi.   Dahil  malaki ang diskwento pag member ng  KanegoSosyO  napakalaking tulong sa aking negosyo at sa aking mga mamimili."</p>
                            <p className="text-light"><b>Amado M. Abe</b><br /><small>Brgy. Talaga, Mabini, Batangas</small></p>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
            {/* <BlockContainer padding={4}>
                <Container>
                    <Row>
                    <Col md="12">
                        <h1 className="text-center">Learn about our great offers</h1>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Agriculture</b></h2>
                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                            <p className="mb-0 lead text-right"> <Link to="/about">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Be a Partner</b></h2>
                            <p>I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now.</p>
                            <p className="mb-0 lead text-right"> <Link to="/partnering">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Membership</b></h2>
                            <p>I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me.</p>
                            <p className="mb-0 lead text-right"> <Link to="/partnering">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Services</b></h2>
                            <p>Like Veterenary Services, educational Services. entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                            <p className="mb-0 lead text-right"> <Link to="/">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Wholesale</b></h2>
                            <p>I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now.</p>
                            <p className="mb-0 lead text-right"> <Link to="/">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Downloads</b></h2>
                            <p>I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me.</p>
                            <p className="mb-0 lead text-right"> <Link to="/downloads">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </BlockContainer> */}
            <Section> 
                <Container>
                    <Header underlined={false} title="SIDC BRANCHES" />
                </Container>
            </Section>
            <BranchesMap />
            <div className="py-3" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 p-4">
                            <h1>Get in contact to us</h1>
                            <p>Need general help on Membership or our Product &amp; Services, send Feedback or just want to send us
                                a message -please use form below.&nbsp;<br /></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 p-4">
                            <img className="img-fluid d-block" alt="SIDC FULL LOGO FOOTER"
                                src={getAssetUrl("app/SIDC-FULL-LOGO-Footer.jpg")} />
                            <p className="lead mt-3">General Contact</p>
                            <p>+ 63 (0)300 4058</p>
                            <p>info@sidc.coop</p>
                            <Link to="/terms-and-condition">Terms and Condition</Link>
                        </div>
                        <div className="col-md-7 p-4">
                            <h5 className="mb-3">Please leave your message here</h5>
                            <Contact />
                        </div>
                    </div>
                </div>
            </div>
            <BlockContainer center={false} padding={0} id="footer">
                <Container>
                    <Row>
                        <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>SIDC.COOP</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_MAIN}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_MAIN + "/about"}>About SIDC</a></li>
                                <li><a href={process.env.GATSBY_SITE_MAIN + "/culture"}>SIDC Culture</a></li>
                                <li><a href={process.env.GATSBY_SITE_MAIN + "/membership"}>Membership</a></li>
                            </ul>
                        </Col>
                        <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Agriculture</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE + '/about'}>About</a></li>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE + '/partnering'}>Partnering</a></li>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE + '/inquire'}>Inquire</a></li>
                            </ul>
                        </Col>
                        {/* <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Consumer Retail</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_CONSUMER}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_CONSUMER + '/shops'}>Shops</a></li>
                                <li><a href={process.env.GATSBY_SITE_CONSUMER + '/partnering'}>Partnering</a></li>
                                <li><a href={process.env.GATSBY_SITE_CONSUMER + '/inquire'}>Inquire</a></li>
                            </ul>
                        </Col> 
                        <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Sorosoro Springs</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_ECO}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/rooms'}>Rooms</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/facilities'}>Facilities</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/dining'}>Dining</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/events'}>Events</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/reservation'}>Reservation</a></li>
                            </ul>
                        </Col>*/}
                        <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Koopinoy</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/programs'}>Programs</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/deposits'}>Deposits</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/loans'}>Loans</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/inquire'}>Inquire</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/#contact'}>Contact</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <p className="text-center my-3">Copyright 2019 Sorosoro Ibaba Development Cooperative (SIDC)</p>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
        </footer>
    )
}

export default Footer