import React from 'react'
//import { Link } from 'gatsby'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn';

const Promos = () => {
    return (
        <BlockContainer padding={1}>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <h1>This Month Favorite Promo</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="p-0">
                        <img alt="PROMO1" className="img-fluid d-block mx-auto" src={getAssetUrl("consumer/Surpricesaya Layout.png")} />
                        <img alt="PROMO2" className="img-fluid d-block mx-auto" src={getAssetUrl("consumer/SIDC Feeds Layout.png")} />
                    </Col>
                </Row>
                {/* <Row>
                    <Col className="my-3">
                        <h1>See all Promos</h1>
                        <Link className="btn btn-primary" to="/">BRING ME TO YOUR PROMOS</Link>
                    </Col>
                </Row> */}
            </Container>
        </BlockContainer>
    )
}

export default Promos
